import { NextApiRequest, NextApiResponse } from 'next';
import Icon from '../components/Icon';
import Layout from '../components/LayoutMinimal';

function Error(err: { statusCode: number }): React.ReactElement {
  const { statusCode } = err;

  // eslint-disable-next-line no-console
  console.error(err);

  return (
    <Layout title={`Error ${statusCode || ''}`}>
      <div className="md:p-12 relative p-6 bg-white rounded-lg shadow-md">
        <div className="relative w-16 h-16 mx-auto mb-6">
          <a href="/">
            <Icon type="question-circle" className="w-16 h-16 text-gray-800" />
          </a>
        </div>
        <div className="relative flex flex-col w-full min-w-0 break-words">
          {statusCode === 404 ? (
            <div className="flex-auto">
              <div className="mb-3 text-center">
                <h1 className="mb-6 text-2xl font-medium text-gray-600">Page not found</h1>
              </div>
              <div className="mb-3 text-center text-gray-800">
                Sorry, can&apos;t find anything <pre className="inline-block">¯\_(ツ)_/¯</pre>
              </div>
              <div className="mt-10 mb-3 text-sm text-center text-gray-600">
                {' '}
                Please contact{' '}
                <a className="link underline" href="mailto:support@serpwall.com">
                  support
                </a>{' '}
                if you think it&apos;s a mistake.
              </div>
            </div>
          ) : (
            <div className="flex-auto">
              <div className="mb-3 text-center">
                <h1 className="mb-6 text-2xl font-medium text-gray-600">
                  Error{statusCode ? ` (${statusCode})` : null}
                </h1>
              </div>
              <div className="mb-3 text-center text-gray-800">
                Ooops, something went wrong <pre>¯\_(ツ)_/¯</pre>
              </div>
              <div className="mt-10 mb-3 text-sm text-center text-gray-600">
                {' '}
                Please contact{' '}
                <a className="link underline" href="mailto:support@serpwall.com">
                  support
                </a>{' '}
                if the problems persist.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="relative pt-6 text-sm text-center text-gray-500">
        Go back to{' '}
        <a href="/" className="hover:no-underline text-gray-500 underline">
          home
        </a>
      </div>
    </Layout>
  );
}

Error.getInitialProps = async ({
  res,
  err,
}: {
  req: NextApiRequest;
  res: NextApiResponse;
  err: { statusCode: number };
}): Promise<{ statusCode: number }> => {
  // eslint-disable-next-line no-nested-ternary
  const statusCode = res?.statusCode ? res.statusCode : err ? err.statusCode : 404;

  return { statusCode };
};

export default Error;
